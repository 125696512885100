<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import ApplicationLogoFull from '@/Components/ApplicationLogoFull.vue';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.email = form.email.toLowerCase();
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};


</script>

<template>

    <Head :title="__('Log in')" />

    <AuthenticationCard>
        <template #logo>
            <div class="w-96 mb-0.5 text-right lg:text-sm text-xs text-slate-500 font-light tracking-wide">{{ $page.props.app.instance }}</div>
            <Link :href="route('welcome')" class="text-4xl text-theme-500">
                <ApplicationLogoFull class="w-96 h-auto fill-theme-500" />
            </Link>
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" :value="__('Email')" />
                <TextInput id="email" v-model="form.email" type="email" class="mt-1 block w-full lowercase" required autofocus />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel for="password" :value="__('Password')" />
                <TextInput id="password" v-model="form.password" type="password" class="mt-1 block w-full" required autocomplete="current-password" />
                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember" />
                    <span class="ml-2 text-sm text-gray-600">{{ __('Remember me') }}</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-600 hover:text-gray-900">
                {{ __('Forgot your password?') }}
                </Link>

                <PrimaryButton id="login-button" class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    {{ __('Log in') }}
                </PrimaryButton>
            </div>
        </form>
    </AuthenticationCard>
</template>
